import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from '../interface/client';
import { environment } from '../../environments/environment';
import { catchError, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { DataService } from './data.service';
import { ClientUtilities } from '../class/client-utilities';
import { LocalStorageService } from './local.storage.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { ApiPaginatedQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { API_ERRORS } from '../shared/lists/errors';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { IsoString } from '../shared/type-aliases/iso-string';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private _isFromMatrix = true;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private storage: AngularFireStorage,
    private localStorageService: LocalStorageService,
    private _dataService: DataService
  ) { }

  async getDefaultId(): Promise<string> {
    const fromLocalStorage = this.localStorageService.get('DEFAULT_CLIENT_ID')
    //const fromLocalStorage = localStorage.getItem('DEFAULT_CLIENT_ID');
    if (fromLocalStorage) {
      return fromLocalStorage
    }
    const defaulClient = await this.getByDocument(environment.DEFAULT_CLIENT_DOCUMENT);
    this.localStorageService.set('DEFAULT_CLIENT_ID', defaulClient.id);
    // localStorage.setItem('DEFAULT_CLIENT_ID', defaulClient.id);
    return defaulClient.id
  }

  async get(id: string): Promise<Client> {
    if (!id) {
      throw new Error('Buscando cliente de id indefinido')
    }
    const url = `${environment.mkgoURL}/api/v1/clients/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    return ClientUtilities.complyApp(response.client);
  }

  async getByDocument(document: string) {
    if (!document) {
      throw new Error("Cliente sem documento");
    }
    const url = `${environment.mkgoURL}/api/v1/clients/document/${document}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    return ClientUtilities.complyApp(response.client);
  }

  /**
   * @param withDefault true to get all clients, including the default client
   * @returns the clients map
   */
  async getAll(withDefault?: boolean): Promise<Client[]> {
    const url = `${environment.mkgoURL}/api/v1/clients`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, options).pipe(first()).toPromise();

    const companyClients = new Map<string, Client>();

    for (const client of response['clients'] as Client[]) {
      ClientUtilities.complyApp(client);
      if (ClientUtilities.isDefault(client.document)) {
        localStorage.setItem('DEFAULT_CLIENT_ID', client.id);
        if (withDefault) {
          companyClients.set(client.id, client);
        }
      } else {
        companyClients.set(client.id, client);
      }
    }

    return Array.from(companyClients.values());
  }

  getPaginated(apiParams: ApiPaginatedQueryParams): Observable<ApiPaginatedResponse<Client>> {
    const url = `${environment.mkgoURL}/api/v1/clients/pages`;
    const params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(this._isFromMatrix, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<Client>>(url, options)),
      first(),
      catchError(err => {
        /** If no on client is found, the API will return a error */
        if (err.error.error === API_ERRORS.personNotFound) {
          return of({
            docs: [],
            hasNextPage: false,
            hasPrevPage: false,
            limit: 0,
            nextPage: null,
            page: 0,
            pagingCounter: 0,
            prevPage: null,
            totalDocs: 0,
            totalPages: 0
          })
        } else {
          throw err;
        }
      })
    );
  }


  getBirthdays(startBirthday: IsoString, endBirthday: IsoString): Observable<Client[]> {
    const url = `${environment.mkgoURL}/api/v1/clients/birthdays`;
    const params = PaginationService.getParams({
      startBirthday, endBirthday
    });
    return this._dataService.httpOptions(this._isFromMatrix, params)
      .pipe(
        switchMap(options => this.http.get<Client[]>(url, options)),
        first(),
        catchError(err => {
          /** If no on client is found, the API will return a error */
          if (err.error.error === API_ERRORS.personNotFound) {
            return of([]);
          } else {
            throw err;
          }
        })
      );
  }

  async add(client: Client): Promise<string> {
    const blob = client.photoBlob;
    const apiClient = ClientUtilities.complyApi(client);
    const url = `${environment.mkgoURL}/api/v1/clients`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: Client = await this.http.post(url, apiClient, header).pipe(first())
      .toPromise();
    client.id = response.id;
    if (blob) {
      client.photoBlob = blob;
      await this.update(client);
    }
    return response.id;
  }

  async update(client: Client): Promise<Client> {
    if (client.photoBlob) {
      await this.updatePhoto(client);
    }
    const apiClient = ClientUtilities.complyApi(client);
    const url = `${environment.mkgoURL}/api/v1/clients/${client.id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.put(url, apiClient, header).pipe(first()).toPromise();
  }

  isExistentPhone(phone: string) {
    const url = `${environment.mkgoURL}/api/v1/clients/exist/phone/${phone}`;
    return this.dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap((options) => this.http.get<boolean>(url, options)),
      first()
    )
  }

  static uniquePhoneValidator(clientService: ClientService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return clientService.isExistentPhone(control.value).pipe(
        distinctUntilChanged(),
        map(existe => (existe ? { telefoneExistente: true } : null)), // Retorna erro se o telefone já existe
        catchError(() => of(null)) // Trata erros de requisição
      );
    };
  }

  private async updatePhoto(client: Client): Promise<void> {
    const path = `${this.dataService.company.id}/clients/${client.id}.jpg`;
    await this.storage.upload(path, client.photoBlob);
    client.photo = await this.storage.ref(path).getDownloadURL().toPromise();
  }

  async uniquePhone(phoneNumber: string): Promise<any> {
    const url = `${environment.mkgoURL}/api/v1/clients/exist/phone/${phoneNumber}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    return response;
  }

  // filterByName(name: string) {
  // const url = `${environment.mkgoURL}/api/v1/clients/filter`;
  // return this.dataService.httpOptions(this._isFromMatrix, PaginationService.getParams({ search: name.trim() }))
  //   .pipe(
  //     switchMap(options => this.http.get<Client[]>(url, options)),
  //     first()
  //   );
  // }

}
