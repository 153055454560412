export enum API_ERRORS {
  internalServerError = 500,
  invalidId = 501,
  duplicatedValue = 502,
  fieldsRequired = 503,
  notFound = 504,
  userNotFound = 505,
  companyNotFound = 506,
  ticketNotFound = 507,
  companyBlocked = 508,
  personNotFound = 509,
  vehicleNotFound = 510,
  roNotFound = 511,
  checklistNotFound = 512,
  invalidCompany = 513,
  invalidPermissions = 514,
  invalidState = 515,
  invalidValue = 516,
  invalidDateFormat = 517,
  invalidBreakdowns = 518,
  invalidParameters = 519,
  biddingNotFound = 520,
  processNotFound = 521
}
