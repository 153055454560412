import { Pipe, PipeTransform } from '@angular/core';
import { API_ERRORS } from '../lists/errors';

@Pipe({
  name: 'apiError'
})
export class ApiErrorPipe implements PipeTransform {

  transform(error: any): any {
    if(error && error.error && error.error.error){
      return API_ERRORS[error.error.error] || 'unknown';
    }
    
    return 'unknown';
  }

}
