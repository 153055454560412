import moment from "moment";
import { toASCII, toUnicode } from "punycode";
import { Client } from "../interface/client";
import { User } from "../interface/user";
import { languages } from '../shared/lists/languages';
import { DocumentPipe } from "../shared/pipes/document.pipe";
import { AddressUtilities } from "./address-utilities";
import { Utilities } from "./utilities";
import { environment } from "../../environments/environment";

export class ClientUtilities extends Utilities {

  static isDefault(document: string){
    return document === environment.DEFAULT_CLIENT_DOCUMENT;
  }

  static changed(a: Client, b: Client): boolean {
      return !this.equalString(a.name, b.name)
        || !this.equalUnmasked(a.document, b.document)
        || !this.equalUnmasked(a.rg, b.rg)
        || !this.equalDate(a.birthday, b.birthday)
        || a.lang != b.lang || a.gender != b.gender
        || a.maritalStatus != b.maritalStatus
        || !this.equalUnmasked(a.phone1, b.phone1)
        || !this.equalUnmasked(a.phone2, b.phone2)
        || !this.equalString(a.email, b.email)
        || !this.equalString(a.type, b.type)
        || a.taxpayer != b.taxpayer
        || a.creditLimit != b.creditLimit
        || a.observation != b.observation
        || a.documentType != b.documentType
        || a.type != b.type
        || a.fancyName != b.fancyName
        || AddressUtilities.changed(a.address, b.address)
        || (a as User).commission !== (b as User).commission 
  }

  static complyApp(client: Client): Client {
    client.name = `${client.name.charAt(0).toUpperCase()}${client.name.substring(1)}`
    client.lang = client.lang == null ? languages.portuguese.id : client.lang;
    client.document = this.removeMask(client.document);
    client.phone1 = this.removeMask(client.phone1);
    client.phone2 = this.removeMask(client.phone2);
    client.email = toASCII((client.email || '').trim());
    client.address = client.address || {};
    ClientUtilities.setNameDocument(client);

    if(client.birthday){
      // fix incorrect timezone for birthday
      const birth = moment(client.birthday.slice(0, 10));
      if(birth.valueOf()){
        client.birthday = birth.toISOString();
      }
    }
    return client;
  }

  static complyApi(client: Client): Client {

    client.document = (client.document).replace(/[\W]/gm, '');
    client.phone1 = this.removeMask(client.phone1);
    client.phone2 = this.removeMask(client.phone2) || ' ';
    client.email = client.email ? toUnicode(client.email) : null;
    client.lastUpdate = new Date().toISOString();

    delete client.nameDocument;
    delete client.photoBlob;
    delete client.photoInput;
    delete client.address.stateName;
    delete client.address.cityName;
    delete client.available;

    if (!client.address.cep) { delete client.address.cep }
    if (!client.address.city) { delete client.address.city }
    if (!client.address.complement) { delete client.address.complement }
    if (!client.address.neighborhood) { delete client.address.neighborhood }
    if (!client.address.number) { delete client.address.number }
    if (!client.address.state) { delete client.address.state }
    if (!client.address.street) { delete client.address.street }
    if (!Object.keys(client.address).length) {
      delete client.address
    }
    if(!client.email){
      delete client.email
    }
    return client;
  }

  static setNameDocument(client: Client) {
    if (client.name && client.document) {
      client.nameDocument = `${client.name} - ${new DocumentPipe().transform(client.document)}`;
    } else {
      client.nameDocument = undefined;
    }
  }

  static splitNameDocument(nameDocument: string): Client {
    const person = { name: '', document: '' };
    const matches = nameDocument.match(/\d/g);
    if (matches) {
      const start = nameDocument.indexOf(matches[0]);
      const end = nameDocument.lastIndexOf(matches[matches.length - 1]);
      const first = nameDocument.substring(0, start).replace(/[./-\s]*$/, '');
      const second = nameDocument.substring(end + 1).replace(/^[./-\s]*/, '');
      person.name = first && second ? `${first} ${second}` : `${first}${second}`;
      person.document = nameDocument.substring(start, end + 1);
    } else {
      person.name = nameDocument;
    }
    return person;
  }

}
