import { Injectable } from '@angular/core';
import  *  as CryptoJS from  'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  key = "T5-innovation-group"
  storage: Storage

  constructor() {
    try {
      this.storage = window.localStorage;
    } catch (error) {
      
    }
    
    // if (typeof localStorage !== 'undefined') {
    //   this.storage = localStorage
    // }else if (typeof sessionStorage !== 'undefined') {
    //   // Fallback to sessionStorage if localStorage is not supported
    //   //sessionStorage.setItem('language', this.language)
    //   this.storage = sessionStorage
    // } else {
    //   // If neither localStorage nor sessionStorage is supported
    //   console.log('Web Storage is not supported in this environment.')
    // }
   }

   

  get(key: string): any {
    if (this.storage) {
      return this.storage.getItem(key)
    }
    return null
  }

  set(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(key, value)
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(key)
      return true
    }
    return false
  }

  clear(): boolean {
    if (this.storage) {
      this.storage.clear()
      return true
    }
    return false
  }

  public getData(key: string): any {
    if (this.storage) {
      return this.storage.getItem(key)
    }
    return null
    // let result: any = ""
    // if(typeof this.storage !== 'undefined')
    //   result = this.storage.getItem(key) 
    // return result 
  }

  public setData(key: string, data: any) {
    this.storage.setItem(key, data)
  }

  // Local storage memorizes data in a key-value form, this means values ​​are stored as strings. 
  // So, if we want to have a real object (or list) to deal with, we must convert the string to a valid JavaScript object. (JSON)
  public getDataJSON(key: string): any {
    return JSON.parse(localStorage.getItem(key)) 
  }

  public saveDataJSON(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  // When using local storage in Angular, the rule of thumb is to not store anything sensitive. 
  // Anything you store in local storage can be accessed by code in your browser.
  public getEncryptedData(key: string) {
    let data = localStorage.getItem(key)|| ""
    return this.decrypt(data)
  }

  public saveEncryptedData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }
  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }

}
